<template>
  <footer class="footer">
    <section class="footer primary-bg">
      <div class="container">
        <div class="footer__top">
          <div class="row row-gap-30">
            <div class="col-lg-4 col-md-12">
              <div class="mb-30">
                <router-link :to="$localePath({ name: 'Home' })">
                  <img
                    :src="require('@/assets/img/logo-light.svg')"
                    class="footer_logo"
                    alt=""
                  />
                </router-link>
              </div>
              <p class="mb-10">
                <a href="mailto:support@fnlgroup.com" class="link-social">
                  E-mail
                </a>
              </p>
              <p>
                <a
                  href="tg://resolve?domain=fnl_support_bot"
                  class="link-social"
                >
                  Telegram
                </a>
              </p>
            </div>

            <!-- Second div -->
            <div class="col-6 col-lg-4 offset-xl-4 col-xl-2 col-md-5">
              <div class="mb-20">
                <router-link
                  :to="$localePath({ name: 'Markets' })"
                  class="footer__title"
                >
                  {{ $t("menu.markets") }}
                </router-link>
              </div>

              <ul class="footer__menu">
                <li>
                  <router-link
                    :to="$localePath({ name: 'Forex' })"
                    class="menu__item"
                  >
                    {{ $t("subMenu.forex") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Indices' })"
                    class="menu__item"
                  >
                    {{ $t("subMenu.indices") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Stock' })"
                    class="menu__item"
                  >
                    {{ $t("subMenu.stock") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Commodities' })"
                    class="menu__item"
                  >
                    {{ $t("subMenu.commodities") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Currency' })"
                    class="menu__item"
                  >
                    {{ $t("subMenu.currency") }}
                  </router-link>
                </li>
              </ul>
            </div>

            <!-- Third div -->
            <div class="col-6 col-lg-4 col-xl-2 col-md-5">
              <div class="footer__title mb-20">{{ $t("company") }}</div>
              <ul class="footer__menu mb-20">
                <li>
                  <router-link
                    :to="$localePath({ name: 'About' })"
                    class="menu__item"
                  >
                    {{ $t("menu.about") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Safety' })"
                    class="menu__item"
                  >
                    {{ $t("menu.safe") }}
                  </router-link>
                </li>
              </ul>
              <div class="mb-20">
                <router-link
                  :to="$localePath({ name: 'Platform' })"
                  class="footer__title"
                >
                  {{ $t("menu.platform") }}
                </router-link>
              </div>

              <router-link
                :to="$localePath({ name: 'Contacts' })"
                class="footer__title"
              >
                {{ $t("menu.contacts") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="footer dark-bg">
      <div class="container">
        <div class="footer__bottom">
          <div class="row row-gap-20">
            <div class="col-lg-12">
              <p class="footer__text">{{ $t("footerText") }}</p>
            </div>
          </div>
          <div class="row row-gap-20 flex-lg-row-reverse">
            <div class="col-lg-6">
              <div class="link-block">
                <router-link
                  :to="$localePath({ name: 'Privacy' })"
                  class="footer__link"
                >
                  {{ $t("privacy") }}
                </router-link>
              </div>
            </div>
            <div class="col-lg-6">
              <p>
                Financial Network Limited © {{ getYear }}, all rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },

  i18n: {
    messages: {
      en: {
        footerText:
          "Your reliable brokerage and investment partner. Investing in the stock markets using leverage involves a high level of risk. There is a possibility of partial loss of investment capital, so it is important not to invest funds that you cannot afford to lose. Before you start trading, be sure to understand the risks associated with this activity. Remember that previous achievements do not guarantee future successes and any trading always involves some risks. Our services and the information on this website should not be considered as personal advice. It is recommended that you seek investment advice from an independent financial adviser.",
      },
      ru: {
        footerText:
          "Ваш надежный брокерский и инвестиционный партнер. Инвестирование на фондовых рынках с использованием кредитного плеча сопряжено с высоким уровнем риска. Существует вероятность частичной потери инвестиционного капитала, поэтому важно не инвестировать средства, которые вы не можете себе позволить потерять. Прежде чем начать торговать, обязательно осознайте риски, связанные с этой деятельностью. Помните, что предыдущие достижения не гарантируют будущих успехов, и любая торговля всегда сопряжена с определенными рисками. Наши услуги и информация на этом сайте не должны рассматриваться как личный совет. Рекомендуется обратиться к независимому финансовому консультанту за инвестиционным советом.",
      },
      fr: {
        footerText:
          "Votre partenaire fiable en matière de courtage et d'investissement. Investir sur les marchés boursiers en utilisant l'effet de levier implique un niveau de risque élevé. Il existe une possibilité de perte partielle du capital investi, il est donc important de ne pas investir des fonds que vous ne pouvez pas vous permettre de perdre. Avant de commencer à négocier, assurez-vous de bien comprendre les risques associés à cette activité. N'oubliez pas que les succès antérieurs ne garantissent pas les succès futurs et que toute opération commerciale comporte toujours des risques. Nos services et les informations contenues dans ce site web ne doivent pas être considérés comme des conseils personnels. Il est recommandé de s'adresser à un conseiller financier indépendant pour obtenir des conseils en matière d'investissement.",
      },
      de: {
        footerText:
          "Ihr zuverlässiger Brokerage- und Anlagepartner. Investitionen an den Aktienmärkten mit Hebelwirkung sind mit einem hohen Risiko verbunden. Es besteht die Möglichkeit eines teilweisen Verlusts des Anlagekapitals. Daher ist es wichtig, dass Sie keine Mittel investieren, deren Verlust Sie sich nicht leisten können. Bevor Sie mit dem Handel beginnen, sollten Sie sich über die mit dieser Tätigkeit verbundenen Risiken im Klaren sein. Denken Sie daran, dass frühere Erfolge keine Garantie für künftige Erfolge sind und jeder Handel immer ein gewisses Risiko beinhaltet. Unsere Dienstleistungen und die Informationen auf dieser Website sollten nicht als persönliche Beratung angesehen werden. Es wird empfohlen, dass Sie sich von einem unabhängigen Finanzberater beraten lassen.",
      },
    },
  },
};
</script>

<style scoped lang="scss">
.footer__top {
  padding: 70px 0 45px;
}
.footer__bottom {
  font-family: "Times New Roman";
  border-top: 1px solid $dark;
  padding: 20px 0;
  font-size: 14px;
  color: $gray2;

  .link-block {
    display: flex;
    align-items: center;
    gap: 20px 45px;
  }
}
.footer__link {
  color: $white;
  text-decoration: underline;

  &:hover {
    color: $light-blue;
    text-decoration: none;
  }
}
.footer__text {
  font-size: 12px;
  color: $gray3;
  margin-bottom: 30px;
}
.footer__title {
  font-family: "Roboto", sans-serif;
  color: $white;
  font-size: 16px;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
}

.footer__menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;

  .menu__item {
    color: $white;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }
}
.link-social {
  font-family: "Roboto", sans-serif;
  color: $white;

  &:hover {
    color: $light-blue;
  }
}
.footer_logo {
  width: 250px;
}

@include media-breakpoint-down(lg) {
  .footer__top {
    padding: 60px 0 40px;
  }
}

@include media-breakpoint-down(sm) {
  .footer__title {
    font-size: 14px;
  }
  .menu__item {
    font-size: 12px;
  }
}
</style>
